/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import { doc, getDoc, db, collection, updateDoc } from "../../service/firebase"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import useDeviceSize from "../../hooks/useDeviceSize.ts"
import OrderListCompact from "./OrderListCompact.tsx"
import { Alert, Divider, Skeleton, Stack } from "@mui/material"
import OrderList from "./OrderList.tsx"
import sendMail from "../../service/email"

const OrderPage = () => {
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { ownerId, storeId, orderId } = useParams()
  const [loading, setLoading] = useState(false)
  const [store, setStore] = useState({})
  const [order, setOrder] = useState({})
  const [storeLink] = React.useState("https://popsto.re") //process.env.REACT_APP_STORE_LINK
  const { mediumScreensUp } = useDeviceSize()

  const confirmToken = searchParams.get("confirm")

  const confirmOrder = async () => {
    const orderRef = await collection(
      db,
      `/StoreOwners/${ownerId}/allStores/${storeId}/Orders`,
    )
    const order = await getDoc(doc(orderRef, orderId))
    await updateDoc(doc(orderRef, orderId), {
      ...order.data(),
      confirmed: true,
    })
    let orderConfirmationEmail = `
    <!doctype html>
    <html lang="en">
    <head>
    <style>
       body{
            font-family: 'Arial', Helvetica, Arial, Lucida, sans-serif;
       }
    </style>
    <title>PopStore Order</title>
    </head>
    <body>
    <h1>Order Confirmation</h1>
    <p>Thank you for your order. Your order from <b>${store?.storeName}</b> has been placed successfully. You can view your order by visiting the following link:</p>
    <p><a href="${storeLink}/order/${ownerId}/${storeId}/${orderRef?.id}">View Order</a></p>
    <p>&nbsp;</p>
    <p>Regards</p>
    <p>PopStore Team</p>
    </body>
    </html>
    `
    sendMail(
      order?.data().email,
      "PopStore Order Confirmation",
      orderConfirmationEmail,
    )
    setOrder(order)
  }

  useEffect(() => {
    if (!order.confirmed && confirmToken === order.confirmToken) {
      confirmOrder(ownerId, storeId, orderId)
    }
  }, [order])

  useEffect(() => {
    setLoading(true)
    ;(async () => {
      const storesRef = await collection(
        db,
        `/StoreOwners/${ownerId}/allStores`,
      )
      const store = await getDoc(doc(storesRef, storeId))
      if (store.exists()) {
        let data = store.data()
        data.columnsList = JSON.parse(data.columnsList)
        setStore(data)
      }
      const orderRef = await collection(
        db,
        `/StoreOwners/${ownerId}/allStores/${storeId}/Orders`,
      )
      const order = await getDoc(doc(orderRef, orderId))
      if (order.exists()) {
        let orderData = order.data()
        orderData.order = JSON.parse(orderData.order)
        let temp = []
        orderData.order.forEach((p) => {
          if (p !== null) {
            if (p.quantity !== 0) {
              temp.push(p)
            }
          }
        })
        orderData.order = temp
        setOrder(orderData)
        setLoading(false)
      } else setLoading(false)
    })()
  }, [navigate, ownerId, storeId, orderId])
  if (loading) return <Skeleton variant="rectangular" height={500} />
  if (!order)
    return (
      <>
        <Typography gutterBottom variant="h4">
          Order information
        </Typography>
        <Typography gutterBottom variant="h6">
          No order could be found
        </Typography>
      </>
    )

  return (
    <>
      {!order.confirmed && (
        <Alert severity="info">
          <Typography>This order is not yet confirmed.</Typography>
        </Alert>
      )}

      <Typography
        style={{ marginBottom: "20px", wordBreak: "break-all" }}
        variant="h4"
      >
        {store?.storeName}
      </Typography>

      <Typography style={{ wordBreak: "break-all" }} variant="h6">
        Order information
      </Typography>
      <Stack sx={{ mb: 3 }}>
        {order.name && (
          <Typography variant="body2">Name: {order.name}</Typography>
        )}
        {order.email && (
          <Typography variant="body2">Email: {order.email}</Typography>
        )}
        {order.phone && (
          <Typography variant="body2">Phone: {order.phone}</Typography>
        )}
        {order.createdAt && (
          <Typography variant="body2">
            Order placed:{" "}
            {new Date(order?.createdAt?.seconds * 1000).toLocaleDateString()}
          </Typography>
        )}
      </Stack>
      <Divider />
      {mediumScreensUp ? (
        <OrderList order={order} store={store} />
      ) : (
        <OrderListCompact order={order} store={store} />
      )}
    </>
  )
}

export default OrderPage
